import * as THREE from "three";

export class TicTacToe {
    constructor(camera, startPostionX = 0, startPosistionY = 0) {
        console.log("tic tac toe game init");
        this.playerX = 'x';
        this.playerO = 'o';

        this.currentPlayer = this.playerX;

        this.camera = camera;

        this.board = new THREE.Group();
        this.board.position.x = startPostionX;
        this.board.position.y = startPosistionY;
        this.board.add(this.createBoard());

        this.hiddenRaycastObjects = new THREE.Group();
        this.board.add(this.hiddenRaycastObjects);
        this.createHiddenTiles();

        this.mouseClickEventTicTacToe = function (event) {
            const raycaster = new THREE.Raycaster();
            const pointer = new THREE.Vector2();
    
            pointer.x = ( event.clientX / window.innerWidth ) * 2 - 1;
            pointer.y = - ( event.clientY / window.innerHeight ) * 2 + 1;
    
            raycaster.setFromCamera( pointer, this.camera );
    
            const intersects = raycaster.intersectObjects(this.hiddenRaycastObjects.children);
            for ( let i = 0; i < intersects.length; i ++ ) {
                const tile = intersects[i].object;
                const tileIndex = this.hiddenRaycastObjects.children.findIndex((t) => t.uuid == tile.uuid);
    
                console.log(`player ${this.currentPlayer} hits ${tile.uuid}`);
    
                if (this.fieldArray[tileIndex] != '') {
                    console.log(`this tile ${tile.uuid} has already been clicked and has value ${this.fieldArray[tileIndex]}`);
                    return;
                }
    
                // set value
                this.fieldArray[tileIndex] = this.currentPlayer;
                this.placeObject(this.currentPlayer, tile.position.x, tile.position.y);
    
                // checkscore
                if (this.checkScore()) {
                    window.removeEventListener("mousedown", this.mouseClickEventTicTacToeHandler);
                    return;
                }
    
                this.currentPlayer = this.currentPlayer == this.playerX ? this.playerO : this.playerX;
            };
        }
        this.mouseClickEventTicTacToeHandler = this.mouseClickEventTicTacToe.bind(this);
        window.addEventListener("mousedown", this.mouseClickEventTicTacToeHandler);

        this.fieldArray = [
            '', '', '',
            '', '', '',
            '', '', ''
        ]

        this.playedTilesGroup = new THREE.Group();
        this.board.add(this.playedTilesGroup);

        this.playerXGeometry = new THREE.BoxGeometry(0.7, 3, 0.8);
        this.playerXMaterial = new THREE.MeshBasicMaterial({color: 0xff0000});

        this.playerOGeometry = new THREE.TorusGeometry( 1.1, 0.4, 12, 10); 
        this.playerOMaterial = new THREE.MeshBasicMaterial({color: 0x00ff00});

        this.instantiekeyPressedEvent();
    }

    instantiekeyPressedEvent() {
        const keyPressed = (e) => {
            if (e.key == 'r') {
                this.reset();
            }
        }

        window.addEventListener("keypress", keyPressed);
    }

    checkScore() {
        // check top horizontal
        if (this.fieldArray[0] == this.currentPlayer && this.fieldArray[1] == this.currentPlayer && this.fieldArray[2] == this.currentPlayer) {
            console.log(`Current player ${this.currentPlayer} won. Row: top horizontal`);
            //draw line
            this.drawLine(3, -2, 0.5);
            return true;
        }

        // check middle horizontal
        if (this.fieldArray[3] == this.currentPlayer && this.fieldArray[4] == this.currentPlayer && this.fieldArray[5] == this.currentPlayer) {
            console.log(`Current player ${this.currentPlayer} won. Row: middle horizontal`);
            this.drawLine(3, -7, 0.5);
            return true;
        }
        // check down horizontal
        if (this.fieldArray[6] == this.currentPlayer && this.fieldArray[7] == this.currentPlayer && this.fieldArray[8] == this.currentPlayer) {
            console.log(`Current player ${this.currentPlayer} won. Row: down horizontal`);
            this.drawLine(3, -12, 0.5);
            return true;
        }
        // check left vertical
        if (this.fieldArray[0] == this.currentPlayer && this.fieldArray[3] == this.currentPlayer && this.fieldArray[6] == this.currentPlayer) {
            console.log(`Current player ${this.currentPlayer} won. Row: left vertical`);
            this.drawLine(-2, -7, 0);
            return true;
        }
        // check middle vertical
        if (this.fieldArray[1] == this.currentPlayer && this.fieldArray[4] == this.currentPlayer && this.fieldArray[7] == this.currentPlayer) {
            console.log(`Current player ${this.currentPlayer} won. Row: middle vertical`);
            this.drawLine(3, -7, 0);
            return true;
        }
        // check right vertical
        if (this.fieldArray[2] == this.currentPlayer && this.fieldArray[5] == this.currentPlayer && this.fieldArray[8] == this.currentPlayer) {
            console.log(`Current player ${this.currentPlayer} won. Row: right vertical`);
            this.drawLine(8, -7, 0);
            return true;
        }
        // check cross left up to right down
        if (this.fieldArray[0] == this.currentPlayer && this.fieldArray[4] == this.currentPlayer && this.fieldArray[8] == this.currentPlayer) {
            console.log(`Current player ${this.currentPlayer} won. Row: cross left up to right down`);
            this.drawLine(3, -7, 0.25, 17.5);
            return true;
        }
        // check cross right up to left down
        if (this.fieldArray[2] == this.currentPlayer && this.fieldArray[4] == this.currentPlayer && this.fieldArray[6] == this.currentPlayer) {
            console.log(`Current player ${this.currentPlayer} won. Row: cross right up to left down`);
            this.drawLine(3, -7, -0.25, 17.5);
            return true;
        }

        return false;
    }

    drawLine(xOffset, yOffset, rotation, lineLength = 13) {
        const lineGeometry = new THREE.BoxGeometry(0.5, lineLength, 1.1);
        const lineMaterial = new THREE.MeshBasicMaterial({color: 0x0000ff});
        const lineMesh = new THREE.Mesh(lineGeometry, lineMaterial);

        lineMesh.position.x = xOffset;
        lineMesh.position.y = yOffset;
        lineMesh.position.z = -0.5;

        lineMesh.rotation.z = Math.PI * rotation;

        this.playedTilesGroup.add(lineMesh);

    }

    reset() {
        console.log('reset game');

        this.fieldArray = [
            '', '', '',
            '', '', '',
            '', '', ''
        ];

        const playedObjects = [];
        this.playedTilesGroup.children.forEach((x) => {
            playedObjects.push(x);
        });

        playedObjects.forEach(o => {
            this.playedTilesGroup.remove(o);
        });

        this.currentPlayer = this.playerX;

        window.addEventListener("mousedown", this.mouseClickEventTicTacToeHandler);
    }

    placeObject(playerValue, xOffset, yOffset) {
        const geometry = playerValue == this.playerX ? this.playerXGeometry : this.playerOGeometry;
        const material = playerValue == this.playerX ? this.playerXMaterial : this.playerOMaterial;
        let playerObject;

        if (playerValue == this.playerX) {
            const mesh1 = new THREE.Mesh(geometry, material);
            mesh1.rotation.z = Math.PI * 0.25;

            const mesh2 = new THREE.Mesh(geometry, material);
            mesh2.rotation.z = Math.PI * -0.25;

            playerObject = new THREE.Group();
            playerObject.add(mesh1);
            playerObject.add(mesh2);

        } else {
            playerObject = new THREE.Mesh(geometry, material);
        }

        playerObject.position.x = xOffset;
        playerObject.position.y = yOffset;
        playerObject.position.z = -0.5;
        this.playedTilesGroup.add(playerObject);
    }


    createHiddenTiles() {
        const hiddenTileBoxGeometry = new THREE.BoxGeometry(4, 4, 1);
        const hiddenTileBoxMaterial = new THREE.MeshBasicMaterial({color: 0x0000ff, opacity: 0, transparent: true})

        this.hiddenRaycastObjects.add(this.createHiddenTile(hiddenTileBoxGeometry, hiddenTileBoxMaterial, -2, -2)); // 1
        this.hiddenRaycastObjects.add(this.createHiddenTile(hiddenTileBoxGeometry, hiddenTileBoxMaterial, 3, -2)); // 2
        this.hiddenRaycastObjects.add(this.createHiddenTile(hiddenTileBoxGeometry, hiddenTileBoxMaterial, 8, -2)); // 3
        this.hiddenRaycastObjects.add(this.createHiddenTile(hiddenTileBoxGeometry, hiddenTileBoxMaterial, -2, -7)); // 4
        this.hiddenRaycastObjects.add(this.createHiddenTile(hiddenTileBoxGeometry, hiddenTileBoxMaterial, 3, -7)); // 5
        this.hiddenRaycastObjects.add(this.createHiddenTile(hiddenTileBoxGeometry, hiddenTileBoxMaterial, 8, -7)); // 6
        this.hiddenRaycastObjects.add(this.createHiddenTile(hiddenTileBoxGeometry, hiddenTileBoxMaterial, -2, -12)); // 7
        this.hiddenRaycastObjects.add(this.createHiddenTile(hiddenTileBoxGeometry, hiddenTileBoxMaterial, 3, -12)); // 8
        this.hiddenRaycastObjects.add(this.createHiddenTile(hiddenTileBoxGeometry, hiddenTileBoxMaterial, 8, -12)); // 9
    }

    createHiddenTile(geometry, material, x, y, z = -0.5) {
        const mesh = new THREE.Mesh(geometry, material);
        mesh.position.x = x;
        mesh.position.y = y;
        mesh.position.z = z;
        return mesh;
    }

    createBoard() {
        const vertices = new Float32Array([
            // front verticies
            0, 0, 0, // 1
            1, 0, 0, // 2
            1, -4, 0, // 3
            1, -5, 0, // 4
            5, -5, 0, // 5
            5, -4 , 0, // 6
            5, 0, 0, // 7
            6, 0, 0, //8
            6, -4, 0, // 9
            10, -4, 0, // 10
            10, -5, 0, // 11
            6, -5, 0, // 12
            6, -9, 0, // 13
            10, -9, 0, // 14
            10, -10, 0, // 15
            6, -10, 0, // 16
            6, -14, 0, // 17
            5, -14, 0, // 18
            5, -10, 0, // 19
            5, -9, 0, // 20
            1, -9, 0, // 21
            1, -10, 0, // 22
            1, -14, 0, // 23
            0, -14, 0, // 24
            0, -10, 0, // 25
            -4, -10, 0, // 26
            -4, -9, 0, // 27
            0, -9, 0, // 28
            0, -5, 0, // 29
            -4, -5, 0, // 30
            -4, -4, 0, // 31
            0, -4, 0, // 32

            // back verticies
            0, 0, -1, // 33
            1, 0, -1, // 34
            1, -4, -1, // 35
            1, -5, -1, // 36
            5, -5, -1, // 37
            5, -4 , -1, // 38
            5, 0, -1, // 39
            6, 0, -1, // 40
            6, -4, -1, // 41
            10, -4, -1, // 42
            10, -5, -1, // 43
            6, -5, -1, // 44
            6, -9, -1, // 45
            10, -9, -1, // 46
            10, -10, -1, // 47
            6, -10, -1, // 48
            6, -14, -1, // 49
            5, -14, -1, // 50
            5, -10, -1, // 51
            5, -9, -1, // 52
            1, -9, -1, // 53
            1, -10, -1, // 54
            1, -14, -1, // 55
            0, -14, -1, // 56
            0, -10, -1, // 57
            -4, -10, -1, // 58
            -4, -9, -1, // 59
            0, -9, -1, // 60
            0, -5, -1, // 61
            -4, -5, -1, // 62
            -4, -4, -1, // 63
            0, -4, -1 // 64
        ]);
            
        const indices = [
            // vertical left
            23, 1, 0, // vertical left front 1
            23, 22, 1, // vertical left front 2
            32, 33, 54, // vertical left back 1
            32, 54, 55, // vertical left back 2

            1, 2, 33, // vertical left top right 1
            34, 33, 2, // vertical left top right 2
            0, 32, 63, // vertical left top left 1
            31, 0, 63, // vertical left top left 2
            0, 1, 32, // vertical left up 1
            33, 32, 1, // vertical left up 2

            3, 20, 35, // vertial left middle right 1
            52, 35, 20, // vertial left middle right 2
            27, 28, 59, // vertial left middle left 1
            60, 59, 28, // vertial left middle left 2

            21, 22, 53, // vertial left bottom right 1
            54, 53, 22, // vertial left bottom right 2
            23, 24, 55, // vertial left bottom left 1
            56, 55, 24, // vertial left bottom left 2
            22, 23, 54, // vertial left bottom bottom 1
            55, 54, 23, // vertial left bottom bottom 2

            // vertical right
            17, 7, 6, // vertical right front 1
            17, 16, 7, // vertical right front 2
            38, 39, 48, // vertical right back 1
            38, 48, 49, // vertical right back 2

            7, 8, 39, // vertical right top right 1
            8, 40, 39, // vertical right top right 2
            38, 37, 6, // vertical right top left 1
            37, 5, 6, // vertical right top left 2
            6, 39, 38, // vertical right up 1
            6, 7, 39, // vertical right up 2

            11, 12, 43, // vertial right middle right 1
            12, 44, 43, // vertial right middle right 2
            36, 19, 4, // vertial right middle left 1
            36, 51, 19, // vertial right middle left 2

            15, 16, 47, // vertial right bottom right 1
            48, 47, 16, // vertial right bottom right 2
            17, 18, 49, // vertial right bottom left 1
            50, 49, 18, // vertial right bottom left 2
            16, 17, 48, // vertial right bottom bottom 1
            49, 48, 17, // vertial right bottom bottom 2

            // horizontal up
            31, 30, 29, // horizontal top left front 1
            29, 28, 31, // horizontal top left front 2
            61, 62, 63, // horizontal top left back 1
            63, 60, 61, // horizontal top left back 2
            31, 63, 62, // horizontal top left top 1
            62, 30, 31, // horizontal top left top 2
            61, 60, 28, // horizontal top left bottom 1
            28, 29, 61, // horizontal top left bottom 2
            62, 61, 29, // horizontal top left left 1
            29, 30, 62, // horizontal top left left 2

            5, 2, 3,// horizontal top middle front 1
            3, 4, 5,// horizontal top middle front 2
            35, 34, 37, // horizontal top middle back 1
            37, 36, 35, // horizontal top middle back 2
            5, 37, 34,// horizontal top middle top 1
            34, 2, 5, // horizontal top middle top 2
            35, 36, 4, // horizontal top middle bottom 1
            4, 3, 35, // horizontal top middle bottom 2
            
            9, 8, 11, // horizontal top right front 1
            11, 10, 9, // horizontal top right front 2
            43, 40, 41, // horizontal top right back 1
            41, 42, 43, // horizontal top right back 2
            9, 41, 40, // horizontal top right top 1
            40, 8, 9, // horizontal top right top 2
            43, 42, 10, // horizontal top right bottom 1
            10, 11, 43, // horizontal top right bottom 2
            41, 9, 10, // horizontal top right right 1
            10, 42, 41, // horizontal top right right 2

            // horizontal down
            27, 26, 25, // horizontal down left front 1
            25, 24, 27, // horizontal down left front 2
            57, 58, 59, // horizontal down left back 1
            59, 56, 57, // horizontal down left back 2
            27, 59, 58, // horizontal down left top 1
            58, 26, 27, // horizontal down left top 2
            57, 56, 24, // horizontal down left bottom 1
            24, 25, 57, // horizontal down left bottom 2
            58, 57, 25, // horizontal down left left 1
            25, 26, 58, // horizontal down left left 2

            19, 20, 21, // horizontal down middle front 1
            21, 18, 19, // horizontal down middle front 2
            53, 52, 51, // horizontal down middle back 1
            51, 50, 53, // horizontal down middle back 2
            19, 51, 52, // horizontal down middle top 1
            52, 20, 19, // horizontal down middle top 2
            53, 50, 18, // horizontal down middle bottom 1
            18, 21, 53, // horizontal down middle bottom 2
            
            13, 12, 15, // horizontal down right front 1
            15, 14, 13, // horizontal down right front 2
            47, 44, 45, // horizontal down right back 1
            45, 46, 47, // horizontal down right back 2
            13, 45, 44, // horizontal down right top 1
            44, 12, 13, // horizontal down right top 2
            47, 46, 14, // horizontal down right bottom 1
            14, 15, 47, // horizontal down right bottom 2
            45, 13, 14, // horizontal down right right 1
            14, 46, 45, // horizontal down right right 2
        ];
            
        const boardGeometry = new THREE.BufferGeometry();
        boardGeometry.setIndex( indices );
        boardGeometry.setAttribute("position", new THREE.BufferAttribute(vertices, 3));
        boardGeometry.computeVertexNormals();

        const boardMaterial = new THREE.MeshNormalMaterial();
        // const boardMaterial = new THREE.MeshBasicMaterial({color: 0x0000ff});
        boardMaterial.flatShading = true;

        const boardMesh = new THREE.Mesh(boardGeometry, boardMaterial);
        return boardMesh;
    }
}