import * as THREE from "three";
import { FontLoader } from "three/examples/jsm/loaders/FontLoader.js"; 
import { TextGeometry } from "three/examples/jsm/geometries/TextGeometry";

export class Text3D {
    constructor() {
        // console.log('Text 3d class loaded');

        const fontLoader = new FontLoader();
        this.textMesh = new THREE.Mesh();
        this.textMesh.position.x = -10;
        this.textMesh.position.y = 10;
        this.textMesh.position.z = -5;

        fontLoader.load(
            '/fonts/helvetiker_regular.typeface.json',
            (font) => {
                // console.log('font loaded');
                const textGeometry = new TextGeometry(
                    `Wilco's playground`,
                    {
                        font: font,
                        size: 2,
                        height: 0.2,
                        curveSegments: 12,
                        bevelEnabled: true,
                        bevelThickness: 0.03,
                        bevelSize: 0.02,
                        bevelOffset: 0,
                        bevelSegments: 5
                    }
                );

                const textMaterial = new THREE.MeshBasicMaterial();
                this.textMesh.geometry = textGeometry;
                this.textMesh.material = textMaterial;
            });
    }
}