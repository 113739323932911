import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";

// import TicTacToe Class
import { TicTacToe } from "./tictactoe.js";

// import Text3D class 
import { Text3D } from "./text3d.js";

/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight,
};

/**
 * Base
 */
// Canvas
const canvas = document.querySelector("canvas.webgl");

// Scene
const scene = new THREE.Scene();

/**
 * Camera
 */
const camera = new THREE.PerspectiveCamera(
    75,
    sizes.width / sizes.height,
    0.1,
    100
);
camera.position.x = 0;
camera.position.y = 0;
camera.position.z = 15;
scene.add(camera);

/** 
* Controls
*/
const controls = new OrbitControls(camera, canvas);
controls.enableDamping = true;
// controls.maxPolarAngle = (Math.PI * 0.4);
// controls.minPolarAngle = (Math.PI * 0.4);
// controls.maxDistance = 35;
// controls.minDistance = 15;

/** 
* Helpers
*/
// const axesHelper = new THREE.AxesHelper( 5 );
// scene.add( axesHelper );

// create text
const text3D = new Text3D();
scene.add(text3D.textMesh);

// create TicTacToe game
const ticTacToeGame = new TicTacToe(camera, -4, 5);
scene.add(ticTacToeGame.board);


// const playgroundGeometry = new THREE.PlaneGeometry(500, 500);
// const playgroundMaterial = new THREE.MeshNormalMaterial();
// const playgroundMesh = new THREE.Mesh(playgroundGeometry, playgroundMaterial);
// playgroundMesh.rotation.x = Math.PI * -0.5;
// scene.add(playgroundMesh);

/**
 * Object
 */
// const geometry = new THREE.BoxGeometry(1, 1, 1)
// const material = new THREE.MeshBasicMaterial({ color: 0xff0000 })
// const mesh = new THREE.Mesh(geometry, material)
// scene.add(mesh)

// class CustomCurve extends THREE.Curve {
//     constructor(scale = 1, amplitude = 1, frequency = 1) {
//         super();
//         this.scale = scale;
//         this.amplitude = amplitude;
//         this.frequency = frequency;
//     }

//     getPoint(t, optionalTarget = new THREE.Vector3()) {
//         const angle = Math.PI * 2 * t;
//         const x = Math.sin(angle * this.frequency) * this.amplitude;
//         const y = t * this.scale;
//         const z = Math.cos(angle * this.frequency) * this.amplitude;

//         return optionalTarget.set(x, y, z).multiplyScalar(this.scale);
//     }
// }

// ---------

// const customCurve = new CustomCurve(1, 2, 1); // Aangepaste parameters: schaal, amplitude, frequentie
// const tubeGeometry = new THREE.TubeGeometry(customCurve, 100, 0.1, 8, false);
// const tubeMaterial = new THREE.MeshBasicMaterial({ color: 0x00ff00 });
// const tubeMesh = new THREE.Mesh(tubeGeometry, tubeMaterial);
// scene.add(tubeMesh);

// // Create a sine-like wave
// const curve = new THREE.SplineCurve([
//     new THREE.Vector2(-10, 0),
//     new THREE.Vector2(-5, 5),
//     new THREE.Vector2(0, 0),
//     new THREE.Vector2(5, -5),
//     new THREE.Vector2(10, 0),
// ]);

// const points = curve.getPoints(50);
// const geometry2 = new THREE.BufferGeometry().setFromPoints(points);

// const material2 = new THREE.LineBasicMaterial({ color: 0xff0000 });

// // Create the final object to add to the scene
// const splineObject = new THREE.Line(geometry2, material2);
// scene.add(splineObject);

// ----------

// maak een tube geometry met een line naar beneden
// algoritme voor curve: https://en.wikipedia.org/wiki/Centripetal_Catmull%E2%80%93Rom_spline
// threeje CatmullRomCurve3: https://threejs.org/docs/?q=curve#api/en/extras/curves/CatmullRomCurve3
// threeje tube geometry: https://threejs.org/docs/?q=tube%20g#api/en/geometries/TubeGeometry
// scroll along spline spline path: https://www.youtube.com/watch?v=58k6PLYnOuI&t=94s&ab_channel=ClassOutside

// // Definieer de punten voor je aangepaste curve
// var points = [];
// points.push(new THREE.Vector3(0, 0, 0));
// points.push(new THREE.Vector3(0, -4, 0));
// points.push(new THREE.Vector3(2, -8, 0));
// points.push(new THREE.Vector3(0, -12, 0));
// points.push(new THREE.Vector3(3, -16, 0));
// points.push(new THREE.Vector3(0, -20, 0));
// points.push(new THREE.Vector3(-2, -26, 0));
// points.push(new THREE.Vector3(-2, -30, 0));
// points.push(new THREE.Vector3(0, -34, 0));
// points.push(new THREE.Vector3(-2, -38, 0));
// points.push(new THREE.Vector3(-2, -42, 0));

// // Maak een aangepaste curve met de punten
// var curve = new THREE.CatmullRomCurve3(points, false);

// // Pas de resolutie van de buis aan (aantal segmenten)
// var tubeGeometry = new THREE.TubeGeometry(curve, 30, 0.2, 8);

// // Maak een materiaal en voeg de buis toe aan de scène
// var material = new THREE.MeshBasicMaterial({ color: 0x00ff00 });
// var tubeMesh = new THREE.Mesh(tubeGeometry, material);
// scene.add(tubeMesh);

// --------

// const curve = new THREE.CubicBezierCurve(
//     new THREE.Vector2(-10, 0),
//     new THREE.Vector2(-5, 15),
//     new THREE.Vector2(20, 15),
//     new THREE.Vector2(10, 0)
// );

// const points = curve.getPoints(50);
// const geometry = new THREE.BufferGeometry().setFromPoints(points);

// const material = new THREE.LineBasicMaterial({ color: 0xff0000 });

// // Create the final object to add to the scene
// const curveObject = new THREE.Line(geometry, material);
// scene.add(curveObject);




/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
});
renderer.setSize(sizes.width, sizes.height);
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

/**
 * Animate
 */
const clock = new THREE.Clock();

const tick = () => {
    const elapsedTime = clock.getElapsedTime();

    // Update controls
    controls.update();

    // Render
    renderer.render(scene, camera);

    // Call tick again on the next frame
    window.requestAnimationFrame(tick);
};

tick();

window.addEventListener("resize", () => {
    // Update sizes
    sizes.width = window.innerWidth;
    sizes.height = window.innerHeight;

    // Update camera
    camera.aspect = sizes.width / sizes.height;
    camera.updateProjectionMatrix();

    // Update renderer
    renderer.setSize(sizes.width, sizes.height);
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
});
